import {t} from 'i18next';
import {Button} from 'primereact/button';
import {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {
  CEvent,
  CProgramHighlight,
  CReferralMode,
  EventsGetDetailResponse,
  EventsService,
  ProgramsGetDetailResponse,
  ProgramsGetDetailResponse_CProgram,
  ProgramsService
} from '../../../../apis/api-client';
import {fd, FiestaListCard, tt} from '../../../../shared';

export type ProgramsCardProps = {
  id: string;
}

export function ProgramsCard(props: ProgramsCardProps) {
  /* Import variables from props. */
  const { id } = props;

  const [program, setProgram] = useState<ProgramsGetDetailResponse_CProgram>();
  const [event, setEvent] = useState<CEvent>();
  const [loading, setLoading] = useState<boolean>(true);

  const navigate = useNavigate();

  /* As soon as this component has been loaded, load the detail data. */
  useEffect(
    () => {
      (
        async () => {
          setLoading(true);

          const program = await loadProgram();
          setProgram(program);

          if (program && program.eventId) {
            const event = await loadEvent(program.eventId);
            setEvent(event);
          }

          setLoading(false);
        }
      )();
    },
    []
  );

  /* Loads the programs and sets them to the state. */
  async function loadProgram() {
    const response: ProgramsGetDetailResponse
      = await ProgramsService.getDetail(id);
    return response.data;
  }

  /* Loads the programs and sets them to the state. */
  async function loadEvent(eventId: string) {
    const response: EventsGetDetailResponse
      = await EventsService.getDetail(eventId);
    return response.data;
  }

  function joinPossible(): boolean {
    if (!program)
      return false;

    if (program.allowWaitlist) {
      return true;
    }

    if (program.mode === CReferralMode.BASKET)
      return true;

    return program.referralsAvailable === true;
  }

  return (
    program?.image
    && event?.title
    && event.id
    && event?.shortDescription
    && event?.logo
    && program?.id
    && program?.title
    && program.referralsAvailable != null
    ?
    <FiestaListCard
      key={program.id}
      image={program.image}
      logo={event.logo}
      title={tt(event.id, 'event_title', event.title)}
      subTitle={tt(program.id, 'program_title', program.title)}
      loading={loading}
    >
      <div className="flex flex-column gap-2 h-full">
        {
          event &&
          event?.happeningFrom &&
            <>
              {
                event?.happeningFrom === event?.happeningTo
                || (event?.happeningFrom && event.happeningTo == null)
                  ? <span><i className='pi pi-calendar'/> {fd(event.happeningFrom, true)}</span>
                  : <span>
                      <i className='pi pi-calendar'/> {fd(event.happeningFrom, false)} {event.happeningTo ? " - " + fd(event.happeningTo, false) : <></>}
                    </span>
              }
            </>
        }
        {
          event &&
          event.location
          &&
          <span>
            <i className='pi pi-map-marker'/> {tt(event.id, 'event_location', event.location)}
          </span>
        }
        {
          program &&
          program.highlights &&
          program.highlights.length > 0 &&
          <>
            <h4 className='mb-2'>{t('domains.programs.components.programs-card.highlights')}</h4>
            <div className='flex flex-column gap-2 ml-2'>
              {
                program &&
                program.highlights &&
                program.highlights.map(
                  (highlight: CProgramHighlight) => {
                    return <>
                      <span><i className='pi pi-star-fill' /> {tt(highlight.id!, 'programhighlight_description', highlight.description!)}</span>
                    </>;
                  }
                )
              }
            </div>
          </>
        }
        <Button
          label={
            t('domains.programs.components.programs-card.buttons.join')
          }
          className='w-full mt-4'
          icon='pi pi-sign-in'
          disabled={!joinPossible()}
          tooltip={
            !joinPossible()
              ? t('domains.programs.components.programs-card.buttons.tooltip.no-slots')
              : undefined
          }
          tooltipOptions={
            {
              showOnDisabled: true,
              position: 'bottom'
            }
          }
          onClick={
            () => {
              navigate('/programs/' + program.id + '/join');
            }
          }
        />
      </div>
    </FiestaListCard>
    :
    <FiestaListCard
      image=''
      logo=''
      title=''
      textContent=''
      key=''
      subTitle=''
      loading={loading}
    />
  );
}

export default ProgramsCard;
