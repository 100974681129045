import { t } from 'i18next';
import { Ripple } from 'primereact/ripple';
import { StyleClass } from 'primereact/styleclass';
import { useRef, type PropsWithChildren } from 'react';
import { useNavigate } from 'react-router-dom';
import logo from '../../../assets/images/fiesta.png';
import { AppBarItem, AppBarUserAction } from './components';
import AppFooter from "src/core/components/app-footer/AppFooter";

export type AppBarProps = Record<string, unknown>;

export function AppBar(props: PropsWithChildren<AppBarProps>) {
  /* Importing navigate for the logo. */
  const navigate = useNavigate();
  const btnReference = useRef(null);

  return (
    <div className='grid'>
      <div className='lg:col-8 lg:col-offset-2 col-12' style={{maxWidth: '1640px'}}>
        <div className='surface-overlay py-3 px-3 flex align-items-center justify-content-between relative lg:static'
             style={{minHeight: '80px'}}>
          <img
            src={logo}
            height={40}
            className='mr-0 lg:mr-6'
            onClick={
              () => {
                navigate('/');
              }
            }
          />
          <StyleClass nodeRef={btnReference} selector='@next' enterFromClassName='hidden' leaveToClassName='hidden'
                      hideOnOutsideClick>
            <a ref={btnReference} className='cursor-pointer block lg:hidden text-700'>
              <i className='pi pi-bars text-4xl'></i>
              <Ripple/>
            </a>
          </StyleClass>
          <div
            className='align-items-center flex-grow-1 justify-content-between hidden lg:flex absolute lg:static w-full surface-overlay left-0 top-100 z-1 shadow-2 lg:shadow-none'>
            <ul className='list-none p-0 m-0 flex lg:align-items-center select-none flex-column lg:flex-row lg:gap-4'>
              <AppBarItem
                label={
                  t('core.components.app-bar.navigation.programs')
                }
                icon='pi-list'
                route='/programs'
              />

              <AppBarItem
                label={
                  t('core.components.app-bar.navigation.discover')
                }
                icon='pi-search'
                route='/discover'
              />
              <div className='lg:hidden'>
                <AppBarItem
                  label={
                    t('core.components.app-bar.userActions.myProfile')
                  }
                  icon='pi-user'
                  route='/profile'
                />
                <AppBarItem
                  label={
                    t('core.components.app-bar.userActions.logOut')
                  }
                  icon='pi-sign-out'
                  route='/logout'
                />
              </div>
            </ul>
            <ul
              className='hidden list-none p-0 m-0 flex select-none flex-column border-top-1 surface-border lg:flex-row lg:border-top-none'>
              <div className='hidden sm:inline-flex'>
                <AppBarUserAction />
              </div>
            </ul>
          </div>
        </div>
        <div className={'mb-8'}>
          {props.children}
        </div>
        <div className='flex flex-column align-items-center pt-8 pb-4 gap-2'>
          <AppFooter />
        </div>
      </div>
    </div>
  );
}

export default AppBar;
