import {changeLanguage, FiestaSimpleDialog} from "src/shared";
import {useEffect, useState} from "react";
import {
  CLanguage, CRegion,
  LanguagesGetResponse,
  LanguagesService,
  MeService,
  ProfilesGetDetailResponse,
  ProfilesGetDetailResponse_CProfile, RegionsGetResponse, RegionsService
} from "src/apis/api-client";
import {Dropdown} from "primereact/dropdown";
import {t} from "i18next";
import {useNavigate} from "react-router-dom";

export type ProfileLanguageRegionDialogProps = {
  onHide?: () => void;
  onChange?: () => void;
  profileId: string;
  visible: boolean;
}

export default function ProfileLanguageRegionDialog(
  props: ProfileLanguageRegionDialogProps
) {
  const navigate = useNavigate();

  const { visible, profileId, onHide, onChange } = props;

  const [profile, setProfile] = useState<ProfilesGetDetailResponse_CProfile | undefined>(undefined);
  const [languages, setLanguages] = useState<CLanguage[]>([]);
  const [regions, setRegions] = useState<CRegion[]>([]);

  const [languageOptions, setLanguageOptions] = useState<CLanguage[]>([]);
  const [languageSelection, setLanguageSelection] = useState<CLanguage>();

  const [regionOptions, setRegionOptions] = useState<CRegion[]>([]);
  const [regionSelection, setRegionSelection] = useState<CRegion>();

  /**
   * Initially load all relevant data.
   */
  useEffect(
    () => {
      (
        async () => {
          await fetchProfile();
          await fetchLanguages();
          await fetchRegions();
        }
      )();
    },
    []
  );

  /* Gets all languages */
  async function fetchLanguages() {
    const response: LanguagesGetResponse
      = await LanguagesService.get();

    if (response.result) {
      setLanguages(response.result);
    }
  }

  /* Gets the profile data */
  async function fetchProfile() {
    const response: ProfilesGetDetailResponse
      = await MeService.getDetail(profileId);

    if (response.data) {
      setProfile(response.data)
    } else {
      setProfile(undefined);
    }
    return response;
  }

  /* Gets the regions data */
  async function fetchRegions() {
    const response: RegionsGetResponse
      = await RegionsService.get();

    if (response.availableRegions) {
      setRegions(response.availableRegions)
    }
    return response;
  }

  /**
   * Saves the changes to the system.
   */
  async function saveChanges() {
    if (profile) {
      if (regionSelection)
        profile.region = regionSelection;

      if (languageSelection)
        profile.languageId = languageSelection.id;

      await MeService.put(profile?.id, {
        data: profile
      });
    }
  }

  /**
   * If the languages have been loaded, set the selected one.
   */
  useEffect(
    () => {
      setLanguageOptions(languages);

      if (profile) {
        const languageItem =
          languages
            .find((option) => option.id === profile.languageId);

        if (languageItem) {
          setLanguageSelection(languageItem);
        }
      }
    },
    [languages]
  );

  /**
   * If the regions have been loaded, set the selected one.
   */
  useEffect(
    () => {
      setRegionOptions(regions);

      if (profile) {
        const regionItem =
          regions
            .find((region) => region === profile.region);

        if (regionItem) {
          setRegionSelection(regionItem);
        }
      }
    },
    [regions]
  );

  /**
   * Change browser language if language changes and save changes.
   */
  useEffect(
    () => {
      (
        async () => {
          if (languageSelection && languageSelection.code) {
            changeLanguage(languageSelection.code);

            await saveChanges();
            navigate(window.location);

            if (onChange)
              onChange();
          }
        }
      )();
    },
    [languageSelection, regionSelection]
  );

  return <>
    <FiestaSimpleDialog
      title={t('profile.components.profileLanguageRegionDialog.title')}
      visible={visible}
      onHide={
        () =>
          onHide
          && onHide()
      }
    >
      <div className={'flex flex-column gap-4'}>
        <div className={'flex flex-column gap-2'}>
          <label htmlFor="languageSelect">{t('profile.components.profileLanguageRegionDialog.language.label')}</label>
          <Dropdown
            id="languageSelect"
            aria-describedby="languageSelect-help"
            options={languageOptions}
            value={languageSelection}
            valueTemplate={(language: CLanguage) => {
              return language && language.code && t(`common.languages.${language.code}.label`)
            }}

            itemTemplate={(language: CLanguage) => {
              return t(`common.languages.${language.code}.label`)
            }}

            onChange={
              (event) =>
                setLanguageSelection(event.value)
            }
            placeholder={t('profile.components.profileLanguageRegionDialog.language.placeholder')}
            loading={!languageOptions || languageOptions.length === 0}
          />
          <small id="languageSelect-help">
            {t('profile.components.profileLanguageRegionDialog.language.help')}
          </small>
        </div>

        <div className={'flex flex-column gap-2'}>
          <label htmlFor="regionSelect">{t('profile.components.profileLanguageRegionDialog.region.label')}</label>
          <Dropdown
            id="regionSelect"
            aria-describedby="regionSelect-help"
            options={regionOptions}
            value={regionSelection}
            valueTemplate={(region: CRegion) => {
              return region && t(`common.regions.${region}`)
            }}

            itemTemplate={(region: CRegion) => {
              return region && t(`common.regions.${region}`)
            }}

            onChange={
              (event) =>
                setRegionSelection(event.value)
            }
            placeholder={t('profile.components.profileLanguageRegionDialog.region.placeholder')}
            loading={!regionOptions || regionOptions.length === 0}
          />
          <small id="regionSelect-help">
            {t('profile.components.profileLanguageRegionDialog.region.help')}
          </small>
        </div>
      </div>
    </FiestaSimpleDialog>
  </>;
}