import {t} from 'i18next';
import {Button} from 'primereact/button';
import {TabPanel, TabView} from 'primereact/tabview';
import {useEffect, useState} from 'react';
import {useParams, useSearchParams} from 'react-router-dom';
import {FiestaImageHeader, FiestaSimpleDialog, getTranslationKey} from 'src/shared';
import {
  CEvent, CProgramLink,
  CSpecialLink,
  EventsGetDetailResponse,
  EventsService,
  ProgramsGetDetailResponse,
  ProgramsGetDetailResponse_CProgram,
  ProgramsService
} from 'src/apis/api-client';
import {
  CommunityManagerCard,
  LinksCard,
  RankingExcerptCard,
  RankingList,
  StatisticCard,
  TransactionsList
} from './components';
import {BasketList} from './components/basket-list';
import {RewardsList} from './components/rewards-list';
import FiestaCountdown from 'src/shared/components/countdown/FiestaCountdown';

export function DashboardView() {
  /* Using params, as we are expecting the program id. */
  const routeParams = useParams();
  const searchParams = useSearchParams();

  const [loading, setLoading] = useState<boolean>(true);
  const [program, setProgram] = useState<ProgramsGetDetailResponse_CProgram>();
  const [event, setEvent] = useState<CEvent>();
  const [fresh, setFresh] = useState<boolean>(false);

  const [groupLink, setGroupLink] = useState<string>();
  const [groupLinkType, setGroupLinkType] = useState<CSpecialLink>();
  const [groupLinkIcon, setGroupLinkIcon] = useState<string>();

  const [activeIndex, setActiveIndex] = useState<number>(0);

  /* Fetch the data for the program and event. */
  useEffect(
    () => {
      (
        async () => {
          if (!routeParams.programId)
            return;
         
          if (searchParams[0].has('fresh'))
            setFresh(true);

          setLoading(true);
          
          const response: ProgramsGetDetailResponse
          = await ProgramsService.getDetail(routeParams.programId);
          
          if (
            response && response.data
          ) {
            if (searchParams[0].has('tab')) {
              switch(searchParams[0].get('tab')) {
                case 'overview':
                  setActiveIndex(0);
                  break;
                case 'basket':
                  if (program?.mode === 'Basket')
                    setActiveIndex(2);
                  break;
                case 'ranking':
                  if (program?.mode === 'Basket')
                    setActiveIndex(3);
                  else
                    setActiveIndex(2);
                  break;
                case 'rewards':
                  if (program?.mode === 'Basket')
                    setActiveIndex(4);
                  else
                    setActiveIndex(3);
                  break;
                case 'transactions':
                  if (program?.mode === 'Basket')
                    setActiveIndex(5);
                  else
                    setActiveIndex(4);
                  break;
              }
            }

            setProgram(response.data);

            // Try to get the WhatsApp group link.
            ProgramsService
              .getLinks(routeParams.programId)
              .then(
                (response) => {
                  if (
                    response
                    && response.result
                    && response.result.length > 0
                  ) {
                    let whatsAppLink;
                    let telegramLink;

                    for (let i = 0; i < response.result?.length; i++) {
                      const link =
                        response.result[i];

                      if (link && link.value) {
                        if (link.special === CSpecialLink.WHATS_APP)
                          whatsAppLink = link.value;
                        if (link.special === CSpecialLink.TELEGRAM)
                          telegramLink = link.value;
                      }
                    }

                    if (whatsAppLink) {
                      setGroupLink(whatsAppLink);
                      setGroupLinkType(CSpecialLink.WHATS_APP);
                      setGroupLinkIcon('whatsapp');
                    }

                    if (telegramLink) {
                      setGroupLink(telegramLink);
                      setGroupLinkType(CSpecialLink.TELEGRAM);
                      setGroupLinkIcon('telegram');
                    }
                  }
                }
              );

            if (response.data.eventId) {
              const eventResponse: EventsGetDetailResponse
                = await EventsService.getDetail(response.data.eventId);

              if (
                eventResponse && eventResponse.data
              ) {
                setEvent(eventResponse.data);
              }
            }
          }

          setLoading(false);
        }
      )();
    },
    [routeParams.programId]
  );

  /* Renders the countdown row. */
  function renderCountdown() {
    return <>
      <div className='flex flex-column align-items-center w-full pt-2'>
        {
          program
          && program.publishingDate
          && program.closingDate
          &&
          <FiestaCountdown
            label={t('domains.programs.views.dashboard.dashboardView.components.countdown.label')}
            size='medium'
            startDate={new Date(program?.publishingDate)}
            targetDate={new Date(program?.closingDate)}
          />
        }
      </div>
    </>;
  }

  /* Renders the statistics row. */
  function renderStatsRow() {
    if (!program || !program.id)
      return;

    return <div className='grid pt-3'>
      <div className='col-6 md:col-3'>
        <StatisticCard programId={program?.id} type='earnedPoints' />
      </div>
      <div className='col-6 md:col-3'>
        <StatisticCard programId={program?.id} type='availablePoints' />
      </div>
      <div className='col-6 md:col-3'>
        <StatisticCard programId={program?.id} type='rank' />
      </div>
      <div className='col-6 md:col-3'>
        <StatisticCard programId={program?.id} type='ambassadors' />
      </div>
    </div>;
  }

  /* Renders the links row. */
  function renderLinksRow() {
    if (!program || !program.id)
      return;

    return <div className='grid pt-2'>
      <div className='col-12 sm:col-6'>
        <CommunityManagerCard programId={program.id} />
      </div>
      <div className='col-12 sm:col-6'>
        <LinksCard programId={program.id} />
      </div>
    </div>;
  }

  /* Renders the links row. */
  function renderRankRow() {
    if (!program || !program.id)
      return;

    return <div className='grid pt-2'>
      <div className='col-12'>
        <RankingExcerptCard programId={program.id} />
      </div>
    </div>;
  }

  /* Renders the whole ranking, including paginator and stuff. */
  function renderRanking() {
    if (!program || !program.id)
      return;

    return <div className='grid pt-2'>
      <div className='col-12'>
        <RankingList programId={program.id} />
      </div>
    </div>;
  }

  /* Renders the whole ranking, including paginator and stuff. */
  function renderRewards() {
    if (!program || !program.id)
      return;

    return <div className='grid pt-2'>
      <div className='col-12'>
        <RewardsList
          showCountdown={true}
          programId={program.id}
        />
      </div>
    </div>;
  }

  /**
   * Renders the transactions list.
   * @returns div, containing the transactions list.
   */
  function renderTransactions() {
    if (!program || !program.id)
      return;

    return <div className='grid pt-2'>
      <div className='col-12'>
        <TransactionsList programId={program.id} />
      </div>
    </div>;
  }

  /**
 * Renders the basket list.
 * @returns div, containing the basket list.
 */
  function renderBaskets() {
    if (!program || !program.id)
      return;

    return <div className='grid pt-2'>
      <div className='col-12'>
        <BasketList programId={program.id} />
      </div>
    </div>;
  }

  /**
   * Opens up the group link to join the group.
   * @returns Nothing.
   */
  function joinGroup() {
    if (!program || !program.id)
      return;

    if (!groupLink)
      return;

    window.open(groupLink, '_blank');
  }

  /**
   * Renders the tab icon.
   * @param icon Icon to render.
   * @param label Label to render.
   * @returns Tab Icon element.
   */
  function renderTabIcon(
    icon: string,
    label: string
  ) {
    return <>
      {
        <div className='flex flex-row w-full'>
          <span
            className={`pi pi-${icon}`}
          />
          <span className='hidden sm:flex ml-2'>
            { label }
          </span>
        </div>
      }
    </>;
  }

  return (
    <div className='flex flex-column flex-wrap'>

      {/* Fresh Dialog - shows a dialog, that the user should join the group. Should increase community building */}
      {
        groupLink
        &&
        <FiestaSimpleDialog
          title={t('domains.programs.views.dashboard.dashboardView.components.freshDialog.header') + ' 🎉'}
          style={{ width: '350px' }}
          visible={fresh}
          closable={false}
          dismissable={true}
          modal={true}
          onHide={
            () => {
              setFresh(false);
            }
          }
        >
          <div>
            <p className='pt-0 mt-0'>
              {
                t('domains.programs.views.dashboard.dashboardView.components.freshDialog.textContent')
              }
            </p>
            <Button
              label={t('domains.programs.views.dashboard.dashboardView.components.freshDialog.buttons.join')}
              icon={`pi pi-${groupLinkIcon}`}
              iconPos='left'
              severity='success'
              className='w-full'
              onClick={
                () =>  {
                  joinGroup();
                  setFresh(false);
                }
              }
            />
          </div>
        </FiestaSimpleDialog>
      }

      <FiestaImageHeader
        loading={loading}
        title={
          event
            && event.title
            && event.id
            ? t(getTranslationKey(event.id, 'event_title')) || event?.title
            : ''
        }
        subTitle={
          program
            && program.title
            && program.id
            ? t(getTranslationKey(program.id, 'program_title')) || program?.title
            : ''
        }
        image={
          program
            && program.image
            ? program.image
            : ''
        }
        logo={
          event
            && event.logo
            ? event.logo
            : ''
        }
        textContent={
          program
            && program.lastRankingDays
            && program.lastRankingDays > 1
            ? t('domains.programs.views.dashboard.dashboardView.header.updatedDaysAgo', {days: program.lastRankingDays})
            : program?.lastRankingDays == 0
              ? t('domains.programs.views.dashboard.dashboardView.header.updatedToday')
              : program?.lastRankingDays == 1
                ? t('domains.programs.views.dashboard.dashboardView.header.updatedYesterday')
                : ''
        }
      />
      {
        <TabView
          activeIndex={activeIndex}
          onTabChange={
            (e) => {
              setActiveIndex(e.index);
            }
          }
          // className='tabview-header-icon'
          panelContainerStyle={
            {
              display: 'none'
            }
          }
        >
          <TabPanel
            header={
              renderTabIcon(
                'table',
                t('domains.programs.views.dashboard.dashboardView.tabs.overview')
              )
            }
            className='flex-1'
          />
          {
            program?.mode === 'Basket' &&
            <TabPanel
              header={
                renderTabIcon(
                  'shopping-cart',
                  t('domains.programs.views.dashboard.dashboardView.tabs.baskets')
                )
              }
              className='flex-1'
            />
          }
          <TabPanel
            header={
              renderTabIcon(
                'chart-line',
                t('domains.programs.views.dashboard.dashboardView.tabs.ranking')
              )
            }
            className='flex-1'
          />
          <TabPanel
            header={
              renderTabIcon(
                'gift',
                t('domains.programs.views.dashboard.dashboardView.tabs.rewards')
              )
            }
            className='flex-1'
          />
          <TabPanel
            header={
              renderTabIcon(
                'arrow-right-arrow-left',
                t('domains.programs.views.dashboard.dashboardView.tabs.transactions')
              )
            }
            className='flex-1'
          />
        </TabView>
      }
      <div
        className='pr-4 pl-4 lg:pr-0 lg:pl-0'
      >
        {
          activeIndex == 0
          &&
          <div>
            {
              !loading
                ? renderStatsRow()
                : <div></div>
            }
            {
              !loading
                ? renderLinksRow()
                : <div></div>
            }
            {
              !loading
                ? renderRankRow()
                : <div></div>
            }
          </div>
        }
        {
          activeIndex == 1
          &&
          <div>
            {
              !loading
              &&
              renderBaskets()
            }
          </div>
        }
        {
          activeIndex == 2
          &&
          <div>
            {
              !loading
                ? renderRanking()
                : <div></div>
            }
          </div>
        }
        {
          activeIndex == 3
          &&
          <div>
            {
              !loading
              &&
              renderRewards()
            }
          </div>
        }
        {
          activeIndex == 4
          &&
          <div>
            {
              !loading
              &&
              renderTransactions()
            }
          </div>
        }
      </div>
    </div>
  );
}