import { Button } from 'primereact/button';
import image from 'src/assets/images/ss_pagenotfound.svg';
import { t } from 'i18next';
import {useState} from "react";

export type PageNotFoundViewProps = {
  error?: any;
}

export function ErrorView(props: PageNotFoundViewProps): JSX.Element {

  const { error } = props;

  const [ showError, setShowError ] =
    useState<boolean>(false);

  return (
    <div className='p-4 lg:p-8'>
      <div className='flex flex-column-reverse lg:flex-row justify-content-center align-items-center'>
        <div>
          <div className='mt-6 mb-3 font-bold text-6xl text-900'>{t('core.router.corerouter.pageNotFound.header')}</div>
          <p className='text-700 text-3xl mt-0 mb-6'>{t('core.router.corerouter.pageNotFound.subTitle')}</p>
          <div className='mb-4'>
            <Button
              type='button'
              label={t('core.router.corerouter.pageNotFound.buttons.tryAgain')}
              onClick={
                () => {
                  if('caches' in window) {
                    caches.keys().then((names) => {
                      names.forEach(async (name) => {
                        await caches.delete(name)
                      })
                    })
                  }

                  window.location.href = window.location.origin + '?t=' + new Date().getTime();
                }
              }
            >
            </Button>
            {
              error
              &&
              <Button
                label={t('core.router.corerouter.pageNotFound.buttons.showError')}
                className={'p-button-outlined ml-2'}
                onClick={
                  () => setShowError(true)
                }
              />
            }
          </div>
          <div>
            {
              error
              && showError
              &&
                <>
                    <small>Error Code</small>
                    <p className='text-500 text-xs' style={{maxWidth: '400px', wordWrap:'break-word'}}>
                      {btoa(JSON.stringify(error))}
                    </p>
                </>
            }
          </div>
        </div>
        <div>
            <img src={image} alt='Image' className='w-full md:w-28rem' />
        </div>
      </div>
    </div>
  );

}
