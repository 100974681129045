import FiestaInfiniteSpinner from 'src/shared/components/infinite-spinner/FiestaInfiniteSpinner';
import {t} from 'i18next';
import {Button} from 'primereact/button';
import {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {FiestaReferralCard} from 'src/shared/components';
import {
  ActionsService,
  AmbassadorRegistrationResponse,
  CEvent,
  CReferralIdentifier,
  CReferralMode,
  CReferralStatus,
  PreCheckResultCode,
  ProgramsGetDetailResponse,
  ProgramsGetDetailResponse_CProgram,
  ProgramsService
} from 'src/apis/api-client';
import {Carousel} from 'primereact/carousel';
import {tt} from "src/shared";
import {Image} from "primereact/image";
import waitingImage from 'src/assets/images/ss_waiting.svg';
import leftArrow from 'src/assets/images/arrow-left.gif';
import rightArrow from 'src/assets/images/arrow-right.gif';

export function JoinView() {
  /* Using params, as we are expecting the program id. */
  const routeParams = useParams();
  const navigate = useNavigate();

  const [loading, setLoading] = useState<boolean>(true);
  const [program, setProgram] = useState<ProgramsGetDetailResponse_CProgram>();
  const [referrals, setReferrals] = useState<CReferralIdentifier[]>();
  const [waitlist, setWaitlist] = useState<boolean>(false);

  /* Fetch the data for the program and event. */
  useEffect(
    () => {
      (
        async () => {
          if (!routeParams.programId) return;

          setLoading(true);

          // Precheck, if the ambassador can register.
          const preCheckResponse =
            await ActionsService.registerAmbassadorPrecheck(routeParams.programId)
              .catch(() => enterDiscover());

          if (
            preCheckResponse
            && preCheckResponse.data
          ) {
            if (preCheckResponse.data.registrationPossible) {
              const programResponse: ProgramsGetDetailResponse
                = await ProgramsService.getDetail(routeParams.programId);

              if (
                programResponse && programResponse.data
              ) {
                setProgram(programResponse.data);

                if (programResponse.data.eventId) {
                  if (programResponse && programResponse.data && programResponse.data.id) {
                    const regResponse: AmbassadorRegistrationResponse =
                      await ActionsService.registerAmbassador(programResponse.data?.id);

                    switch (regResponse.status)
                    {
                      case CReferralStatus.REGISTERED:
                        if (programResponse.data.mode === CReferralMode.BASKET)
                          enterProgram(programResponse.data?.id);

                        if (regResponse && regResponse.data) {
                          setReferrals(regResponse.data);
                        }
                        break;
                      case CReferralStatus.WAITLIST:
                        setWaitlist(true);
                        break;
                      default:
                        enterDiscover();
                    }
                  }
                }
              }
            } else {
              if (preCheckResponse.data.resultCode == PreCheckResultCode.ALREADY_REGISTERED) {
                enterProgram(routeParams.programId);
              } else if (preCheckResponse.data.resultCode == PreCheckResultCode.WAITLIST) {
                enterPrograms();
              } else {
                navigate('/programs/sorry/' + preCheckResponse.data.resultCode);
              }
            }
          } else {
            enterDiscover();
          }

          setLoading(false);
        }
      )();
    },
    [routeParams.programId]
  );

  /**
   * Redirect to the discover view.
   */
  function enterDiscover() {
    navigate('/discover');
  }

  /**
   * Redirect to the discover view.
   */
  function enterPrograms() {
    navigate('/programs');
  }

  /**
   * Redirect to the program dashboard.
   * @param programId The program id.
   */
  function enterProgram(programId: string) {
    navigate('/programs/' + programId + '/dashboard?fresh=true');
  }

  /**
   * Render the referrals.
   * @param referrals The referrals to render.
   * @returns The rendered referrals.
   */
  function renderReferrals(referrals: CReferralIdentifier[]) {
    return <>
      <Carousel
        className='h-full w-full flex flex-column justify-content-center align-content-center'
        value={referrals}
        showIndicators={false}
        itemTemplate={
          (referral: CReferralIdentifier) => <FiestaReferralCard data={referral}/>
        }
        showNavigators={referrals.length > 1}
      />
    </>;
  }

  /**
   * Renders the info message, if referrals have been assigned.
   */
  function renderReferralInfo() {
    return <>
      <div className={'col-12 md:col-6'}>
        <p className={'text-5xl font-bold'}>
          { t('domains.programs.views.join.joinView.referralMessage.header') }
        </p>
        {
          program?.id
          && program.referralInfo
          &&
          <p className={'text-2xl'}
             dangerouslySetInnerHTML={{
               __html: tt(program.id, 'program_referralInfo', program.referralInfo)
             }}
          />
        }
        <div className={'md:hidden'}>
          <div className={'flex flex-row justify-content-center'}>
            <Image src={leftArrow} width={'96px'} />
            <Image src={rightArrow} width={'96px'} />
          </div>
          {
            !referrals
              ? <></>
              : renderReferrals(referrals)
          }
        </div>
        {
          program
          && program.id
          &&
          <Button
            className={'mt-4 w-full md:w-auto'}
            label={t('domains.programs.views.join.joinView.referralMessage.buttonText')}
            onClick={() => enterProgram(program.id ?? '')}
          />
        }
      </div>
      <div className={'hidden md:block md:col-6 md:flex md:flex-column'}>
        <div className={'flex flex-row justify-content-center'}>
          <Image src={leftArrow} width={'96px'}/>
          <Image src={rightArrow} width={'96px'}/>
        </div>
        <div className={'flex flex-row'}>
          {
            !referrals
              ? <></>
              : renderReferrals(referrals)
          }
        </div>
      </div>
    </>;
  }

  /**
   * Renders a view, which explains the user why he is on the waitlist.
   */
  function renderWaitlistInfo() {
    return <>
      <div className={'col-12 md:hidden'}>
      <Image src={waitingImage}/>
      </div>
      <div className={'col-12 md:col-7'}>
        <p className={'text-5xl font-bold'}>
          { t('domains.programs.views.join.joinView.waitlistMessage.header') }
        </p>
        <p className={'text-2xl'}>
          { t('domains.programs.views.join.joinView.waitlistMessage.textContent') }
        </p>
        <Button
          label={t('domains.programs.views.join.joinView.waitlistMessage.buttonText')}
          onClick={enterDiscover}
        />
      </div>
      <div className={'md:col-5'}>
        <Image src={waitingImage}/>
      </div>
    </>;
  }

  return (
    <>
      <div className='grid p-4 mt-4'>
        {
          referrals != null
          &&
          renderReferralInfo()
        }
        {
          waitlist
          &&
          renderWaitlistInfo()
        }
        {
          loading
          &&
          <div className='col-12'>
              <div className='flex flex-column align-items-center'>
                  <FiestaInfiniteSpinner/>
              </div>
          </div>
        }
      </div>
    </>
  );
}
