import {useEffect, useState} from "react";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import WelcomeRegionSelect from "src/domains/welcome/components/region-select/WelcomeRegionSelect";

enum WelcomeSteps {
  REGION = "Region",
  PROFILE = "Profile"
}

export function WelcomeView() {
  // Import params.
  const location = useLocation();
  const params = useParams();
  const navigate = useNavigate();

  const [ steps, setSteps ] = useState<WelcomeSteps[]>([]);
  const [ currentStep, setCurrentStep ] = useState<WelcomeSteps>();
  const [ stepIndex, setStepIndex ] = useState<number>(0);

  useEffect(
    () => {
      const configuration =
        location.state;

      const steps = [];

      if (configuration && configuration.askRegion)
        steps.push(WelcomeSteps.REGION);

      if (configuration && configuration.askProfile)
        steps.push(WelcomeSteps.PROFILE);

      setCurrentStep(steps[stepIndex]);
      setSteps(steps);

      // If no steps are left, redirect the user to the programs.
      if (steps.length == 0)
        navigate('/programs');
    },
    [params]
  );

  /**
   * Callback, which updates the step which should be completed next.
   */
  function onCompleted() {
    // This was the last step.
    if (stepIndex == steps.length - 1) {
      navigate('/programs');
      return;
    }

    const nextStep =
      steps[stepIndex + 1];

    // Evaluate and set next step.
    setStepIndex(stepIndex + 1);
    setCurrentStep(nextStep);

    // Evaluate, if step is profile.
    if (nextStep === WelcomeSteps.PROFILE)
      navigate('/profile', { state: true });
  }

  return <>
    <div className='flex flex-row justify-content-center align-items-center h-screen w-screen'>
      <div className='flex flex-row' style={{maxWidth: '1240px'}}>
        {
          currentStep === WelcomeSteps.REGION
          &&
          <WelcomeRegionSelect onCompleted={onCompleted} />
        }
        {/* Second step is currently not implemented here, it will be soon (Profile). */}
      </div>
    </div>
  </>;
}