import logo from "src/assets/images/fiesta.png";
import {Button} from "primereact/button";
import {t} from "i18next";

export default function AppFooter() {
  /* Links */
  const links = [
    {
      label: "core.components.app-footer.links.imprint",
      link: "https://fiesta.club/imprint"
    },
    {
      label: "core.components.app-footer.links.privacyPolicy",
      link: "https://fiesta.club/privacy-policy"
    },
    {
      label: "core.components.app-footer.links.support",
      link: "mailto:support@fiesta.club"
    }
  ]

  return <>
    <img alt={'fiesta logo'} src={logo} style={{width: '96px'}}/>
    <div className={'flex flex-row flex-wrap justify-content-center gap-1'}>
      {
        links.map(
          link =>
            <Button
              link
              label={t(link.label)}
              onClick={
                () => {
                  window.open(link.link);
                }
              }
            />
        )
      }
    </div>
  </>;
}