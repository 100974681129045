import { Route, Routes } from 'react-router-dom';
import { DashboardView } from '../views';
import { JoinView } from '../views/join';
import { ProgramsView } from '../views/programs/ProgramsView';
import { NotPossibleView } from '../views/join/NotPossibleView';

export function ProgramsRouter() {
  return (
    <Routes>
      <Route path={'/'} element={<ProgramsView />}></Route>
      <Route path={'/:programId/join'} element={<JoinView />}></Route>
      <Route path={'/:programId/dashboard'} element={<DashboardView />}></Route>
      <Route path={'/sorry/:reason'} element={<NotPossibleView />}></Route>
    </Routes>
  );
}

export default ProgramsRouter;
