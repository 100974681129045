/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum CReferralIdentifierLabel {
    NONE = 'None',
    DISCOUNT_CODE = 'DiscountCode',
    DISCOUNT_LINK = 'DiscountLink',
    ACCESS_CODE = 'AccessCode',
    ACCESS_LINK = 'AccessLink',
    SHOP_LINK = 'ShopLink',
}
