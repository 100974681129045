/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum CRegion {
    NORTH_AMERICA = 'NorthAmerica',
    SOUTH_AMERICA = 'SouthAmerica',
    EUROPE = 'Europe',
    ASIA = 'Asia',
    AFRICA = 'Africa',
    OCEANIA = 'Oceania',
}
