import {t} from 'i18next';
import {Button} from 'primereact/button';
import {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {
  CEvent,
  CReferralIdentifier,
  CReferralMode,
  CReferralStatus,
  EventsGetDetailResponse,
  EventsService,
  ProgramsGetDetailResponse,
  ProgramsGetDetailResponse_CProgram,
  ProgramsService,
  ReferralIdentifiersGetResponse
} from '../../../../apis/api-client';
import {FiestaListCard, FiestaReferralCard, FiestaSimpleDialog, getTranslationKey, tt} from '../../../../shared';
import {Carousel} from 'primereact/carousel';
import {Message} from "primereact/message";

export type ProgramReferralsCardProps = {
  id: string;
}

export function ProgramReferralsCard(props: ProgramReferralsCardProps) {
  /* Import variables from props. */
  const { id } = props;

  const navigate = useNavigate();

  const [program, setProgram] = useState<ProgramsGetDetailResponse_CProgram>();
  const [event, setEvent] = useState<CEvent>();
  const [loading, setLoading] = useState<boolean>(true);
  const [infoVisible, setInfoVisible] = useState<boolean>(false);
  const [referrals, setReferrals] = useState<CReferralIdentifier[]>();
  const [referralStatus, setReferralStatus] = useState<CReferralStatus>();

  /* As soon as this component has been loaded, load the detail data. */
  useEffect(
    () => {
      (
        async () => {
          setLoading(true);

          const program = await loadProgram();
          setProgram(program);

          if (program && program.eventId) {
            const event = await loadEvent(program.eventId);
            setEvent(event);
          }

          if (program && program.id) {
            const referralsData = await loadReferrals(program.id);
            if (referralsData.result)
              setReferrals(referralsData.result);
            if (referralsData.status)
              setReferralStatus(referralsData.status);
          }

          setLoading(false);
        }
      )();
    },
    []
  );

  /* Loads the programs. */
  async function loadProgram() {
    const response: ProgramsGetDetailResponse
      = await ProgramsService.getDetail(id);
    return response.data;
  }

  /* Loads the programs. */
  async function loadEvent(eventId: string) {
    const response: EventsGetDetailResponse
      = await EventsService.getDetail(eventId);
    return response.data;
  }

  /* Loads the referrals. */
  async function loadReferrals(programId: string) {
    const response: ReferralIdentifiersGetResponse
      = await ProgramsService.getReferralIdentifiers(programId);
    return response;
  }

  /* Navigates to the dashboard. */
  function enterDashboard() {
    navigate('/programs/' + program?.id + '/dashboard');
  }

  function renderReferrals(referrals: CReferralIdentifier[]) {
    return <>
      <Carousel
        className='w-full'
        value={referrals}
        showIndicators={false}
        itemTemplate={
          (referral: CReferralIdentifier) => <FiestaReferralCard data={referral} />
        }
        showNavigators={referrals.length > 1}
      />
    </>;
  }

  function renderBasketButton(program: ProgramsGetDetailResponse_CProgram) {
    return <>
      <div className='flex-1 pt-2 pb-2'>
        <Button
          icon="pi pi-shopping-cart"
          label={t('domains.programs.components.program-referrals-card.buttons.basketCreateButton')}
          className='p-button-outlined'
          onClick={() => navigate(`/basket/${program.id}/create`)}
        />
      </div>
    </>;
  }

  return (
    program?.image
    && event?.title
    && event?.id
    && event?.shortDescription
    && event?.logo
    && program?.id
    && program?.title
      ?
      <FiestaListCard
        image={program.image}
        logo={event.logo}
        title={t(getTranslationKey(event.id, 'event_title')) || event.title}
        textContent={''}
        key={program.id}
        subTitle={t(getTranslationKey(program.id, 'program_title')) || program.title}
        loading={loading}
      >
        <div
          className='flex flex-column gap-4 mt-2 mr-2'
        >
          <div className='flex flex-wrap flex-row'>
            {
              program.referralInfo
              && referrals
              && referrals.length > 0
              &&
              <div className='flex justify-content-end w-full'>
                <span className='pi pi-info-circle' onClick={() => setInfoVisible(true)}></span>
              </div>
            }
            {
              referrals
              &&
              program.mode === CReferralMode.CODES
                ? renderReferrals(referrals)
                : renderBasketButton(program)
            }
          </div>
          {
            referralStatus
            && referralStatus == CReferralStatus.WAITLIST
            &&
            <Message text={"You're on the waitlist for this program. As soon as a slot is available, you will get a notification via e-mail."}/>
          }
          {
            referralStatus
            && referralStatus == CReferralStatus.REGISTERED
            &&
            <Button
              label={t('domains.programs.components.program-referrals-card.buttons.enter')}
              icon='pi pi-chart-line'
              onClick={enterDashboard}
            />
          }
        </div>
        {
          program.referralInfo
          &&
          <FiestaSimpleDialog
            title={<><span className='pi pi-info-circle mr-2'></span> { t('domains.programs.components.program-referrals-card.dialog.title') }</>}
            visible={infoVisible}
            style={{width: '300px'}}
            onHide={() => setInfoVisible(false)}
          >
            <p dangerouslySetInnerHTML={{ __html: tt(program.id, 'program_referralInfo', program.referralInfo) }} />
          </FiestaSimpleDialog>
        }
      </FiestaListCard>
      :
      <FiestaListCard
        image=""
        logo=""
        title=""
        textContent=""
        key=""
        subTitle=""
        loading={loading}
      />
  );
}

export default ProgramReferralsCard;
