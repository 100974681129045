import {Dropdown} from "primereact/dropdown";
import React, {useEffect, useState} from "react";
import {
  CRegion,
  MeService,
  ProfilesGetDetailResponse,
  RegionsGetResponse,
  RegionsService,
  UsersMeResponse
} from "src/apis/api-client";

import afSvg from 'src/assets/images/world_af.svg';
import asSvg from 'src/assets/images/world_as.svg';
import euSvg from 'src/assets/images/world_eu.svg';
import naSvg from 'src/assets/images/world_na.svg';
import ocSvg from 'src/assets/images/world_oc.svg';
import saSvg from 'src/assets/images/world_sa.svg';
import {Button} from "primereact/button";
import {t} from "i18next";

type RegionSelectOption = {
  label?: string;
  value: CRegion;
}

export type WelcomeRegionSelectProps = {
  onCompleted: () => void;
}

export default function WelcomeRegionSelect(props: WelcomeRegionSelectProps): JSX.Element {
  // Import actions.
  const { onCompleted } = props;

  // Variable declarations.
  const [svg, setSvg] = useState<string>(euSvg);
  const [selectedRegion, setSelectedRegion] = useState<CRegion>(CRegion.EUROPE);
  const [options, setOptions] = useState<RegionSelectOption[]>([]);
  const [isSaving, setIsSaving] = useState(false);

  /**
   * Get the regions and convert them to options.
   */
  useEffect(
    () => {
      (
        async () => {
          try {
            await getAvailableRegions()
          } catch { }

          const suggestionSet =
            await getSuggestion();
          if (!suggestionSet)
          {
            setSelectedRegion(options[0].value);
          }
        }
      )();
    },
    []
  );

  /**
   * Updating the svg string, based on the selected region.
   */
  useEffect(
    () => {
      switch (selectedRegion)
      {
        case CRegion.AFRICA:
          setSvg(afSvg); break;
        case CRegion.ASIA:
          setSvg(asSvg); break;
        case CRegion.EUROPE:
          setSvg(euSvg); break;
        case CRegion.NORTH_AMERICA:
          setSvg(naSvg); break;
        case CRegion.OCEANIA:
          setSvg(ocSvg); break;
        case CRegion.SOUTH_AMERICA:
          setSvg(saSvg); break;
      }
    },
    [selectedRegion]
  );

  /**
   * Gets the available regions from the backend and adds them as options to the list.
   */
  async function getAvailableRegions() {
    const response: RegionsGetResponse =
      await RegionsService
        .get();

    if (
      response.availableRegions
      && response.availableRegions.length > 0
    )
    {
      const regionOptions: RegionSelectOption[]
        = [];

      response
        .availableRegions
        .sort()
        .map(
          (value: CRegion) => {
            regionOptions.push({ label: t(`common.regions.${value}`), value: value });
          }
        );

      setOptions(regionOptions);
    }
  }

  /**
   * Saves the region to the profile and calls the onCompleted callback.
   */
  async function saveRegion()
  {
    setIsSaving(true);

    try {
      const profileResponse: ProfilesGetDetailResponse =
        await MeService.getDetail();

      if (profileResponse.data) {
        profileResponse
          .data
          .region = selectedRegion;

        await MeService.put(
          profileResponse.data.id,
          {
            data: profileResponse.data
          }
        );

        onCompleted();
      }
    }
    catch (e) { }

    setIsSaving(false);
  }

  /**
   * Gets a suggestion from the API, which region would be the best fit for the user.
   */
  async function getSuggestion(): Promise<boolean> {
    const meResponse: UsersMeResponse =
      await MeService
        .getMe();

    if (meResponse) {
      if (meResponse.suggestedRegion)
      {
        const foundOption: RegionSelectOption | undefined =
          options
            .find(
              o =>
                o.value === meResponse.suggestedRegion
            );

        if (foundOption) {
          setSelectedRegion(
            meResponse.suggestedRegion
          );
          return true;
        }
      }
    }

    return false;
  }

  return <>
    <div className='flex flex-1 flex-column justify-content-center p-4'>
      <p className={'text-5xl font-bold'}>{t('domains.welcome.components.region-select.title')}</p>
      <p
        className={'text-2xl mt-0'}
        dangerouslySetInnerHTML={{__html: t('domains.welcome.components.region-select.subTitle')}}
      />
      {
        options
        && options.length > 0
        &&
        <Dropdown
          value={selectedRegion}
          valueTemplate={
            (option: RegionSelectOption) => option.label
          }
          optionValue={'value'}
          onChange={
            (ev) => setSelectedRegion(ev.target.value)
          }
          options={options}
        />
      }
      <Button
        icon={'pi pi-check'}
        label={t('domains.welcome.components.region-select.buttonLabel')}
        className={'mt-4'}
        style={{maxWidth: '150px'}}
        outlined={false}
        onClick={() => saveRegion()}
        disabled={isSaving}
      />
    </div>
    <div className='hidden md:flex md:flex-1 flex-column align-items-center justify-content-center p-4'>
      <img alt={'Continents Map'} src={svg} width={'100%'}/>
    </div>
  </>
}