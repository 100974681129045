/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum CReferralStatus {
    WAITING_FOR_REGISTRATION = 'WaitingForRegistration',
    REGISTERED = 'Registered',
    UNREGISTERED = 'Unregistered',
    WAITLIST = 'Waitlist',
}
