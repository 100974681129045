import { ProgramsRouter } from 'src/domains/programs/_router';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import { AppBar, StayTunedMessage } from 'src/core';
import { DiscoverView } from 'src/domains/programs';
import { RewardsRouter } from 'src/domains/rewards';
import {ErrorView, changeLanguage, loadTranslation, loginRequest} from 'src/shared';
import { useEffect, useState } from 'react';
import { ApiError, MeService } from 'src/apis/api-client';
import { ProfileRouter } from 'src/domains/profile';
import { BasketRouter } from 'src/domains/basket';
import { FlowsRouter } from 'src/domains/flows';
import {useMsal} from "@azure/msal-react";
import {WelcomeRouter} from "src/domains/welcome/_router/WelcomeRouter";

export function CoreRouter() {

  const [ notified, setNotified ] = useState<boolean>(false);
  const [ error, setError ] = useState<boolean>(false);
  const [ errorObj, setErrorObj ] = useState<any>();

  const navigate = useNavigate();
  const msalInstance = useMsal();

  /**
   * Calls the UserService getMe method.
   * This will create the user, if it doesn't exist in the database for now.
   * Also, during this operation, the referrals will get auto assigned.
   */
  useEffect(
    () => {
      (
        async () => {
          try {
            const val = await MeService.getMe();
            if (val) {
              await loadTranslation();
              setNotified(true);

              // Change the language based on the profile setting.
              if (val.languageCode) {
                changeLanguage(val.languageCode);
              }

              // Redirect to profile, if he hasn't one and it's not a flow URL.
              if (!window.location.pathname.startsWith('/flows', 0)) {
                if (
                  val.hasProfile === false
                  || val.hasRegion === false
                ) {
                  navigate(
                    '/welcome',
                    {
                      state:
                      {
                        askRegion: !val.hasRegion,
                        askProfile: !val.hasProfile,
                      }
                    }
                  );
                }
              }
            } else {
              setError(true);
              setErrorObj({ message: `Couldn't get UsersMeResponse.` });
            }
          } catch (error: any) {
            /**
             * Should be a fix for the following problem:
             * https://github.com/AzureAD/microsoft-authentication-library-for-js/issues/349
             */
            if (
              error &&
              error.errorCode === "interaction_required"
            )
            {
              await msalInstance.instance.loginRedirect(loginRequest);
              return;
            }

            /**
             * If the error is an instance of ApiError, then we can check the status code.
             * If the status code is 400, then the user is not logged in.
             * This means, a redirect-utils will occur automatically. This way, we can avoid that the error page is shown.
             * The error should only be shown, if the status code is not 400.
             */
            if (error instanceof ApiError) {
              if (error.status !== 400 && error.status !== 401) {
                setError(true);
              }
            } else {
              setError(true);
            }

            setErrorObj(error);
          }
        }
      )();
    },
    []
  );

  return (
    notified
      ? <Routes>
          <Route path='/flows/*' element={<FlowsRouter />} />
          <Route path='/programs/*' element={<AppBar><ProgramsRouter /></AppBar>} />
          <Route path='/discover' element={<AppBar><DiscoverView /></AppBar>} />
          <Route path='/rewards/*' element={<AppBar><RewardsRouter /></AppBar>} />
          <Route path='/profile/*' element={<AppBar><ProfileRouter /></AppBar>} />
          <Route path='/basket/*' element={<AppBar><BasketRouter /></AppBar>} />
          <Route path='/welcome/*' element={<WelcomeRouter />} />
          <Route path='/' element={<Navigate to='/programs' replace />} />

          { /* Routes to defaults. */}
          <Route
            path='404'
            element={<ErrorView />}
          />
          <Route
            path='*'
            element={<Navigate to='/404' replace />}
          />
        </Routes>
      : error
        ? <ErrorView error={errorObj} />
        : <StayTunedMessage />
  );
}

export default CoreRouter;
