import { CProgramLink, ProgramLinksGetResponse, ProgramsService } from 'src/apis/api-client';
import { Card } from 'primereact/card';
import { useEffect, useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { ProgramRules } from 'src/domains/programs/components';
import { t } from 'i18next';
import { Button } from 'primereact/button';
import { tt, tte } from 'src/shared';

export type LinksCardProps = {
  programId: string;
}

export function LinksCard(props: LinksCardProps) {

  /* Import variables from props. */
  const { programId } = props;

  const [loading, setLoading] = useState<boolean>(false);
  const [links, setLinks] = useState<CProgramLink[]>();
  const [visible, setVisible] = useState(false);
  const isMobile = window.innerWidth <= 768;

  /* Fetch the links for the program */
  useEffect(
    () => {
      (
        async () => {
          setLoading(true);

          const links = await fetchLinks();

          if (links)
            setLinks(links);

          setLoading(false);
        }
      )();
    },
    [programId]
  );

  // Sets visible on false if the dialog opens on mobile device.
  useEffect(
    () => {
      if (isMobile) {
        setVisible(false);
      }
    }, [isMobile]
  );

  /**
   * Gets all links for this program.
   * @returns LinksGetResponse
   */
  async function fetchLinks() {
    const response: ProgramLinksGetResponse =
      await ProgramsService.getLinks(programId);

    if (response && response.result != null) {
      return response.result;
    }
  }

  /**
   * Opens a new tab in the browser.
   * @param link The link value.
   */
  function openLink(link: string | undefined | null) {
    if (!link)
      return;

    window.open(link, '_blank');
  }

  /**
   * Render the dialog that shows the rules.
   */
  function renderDialog() {
    return <div>
      <Dialog
        header={t('domains.programs.views.dashboard.dashboardView.components.links-card.ruleDialogHeader')}
        visible={visible}
        closable={true}
        className='lg:w-4'
        maximized={isMobile}
        onHide={() => setVisible(false)}>
        <div className='flex flex-column gap-4'>
          {
            <ProgramRules viewOnly={true} programId={programId} allChecked={() => true} />
          }
        </div>
      </Dialog>
    </div>
  }

  /**
   * Renders the link.
   */
  function renderLink(link: CProgramLink) {
    if (!link || link.value == null)
      return <></>;

    return <>
      <Button
        className='flex text-left pl-0'
        icon={link.icon ? `pi pi-${link.icon}` : 'pi pi-link'}
        label={tte(link.id!, 'programlink', 'title', link.title!)}
        onClick={
          () => {
            if (link.value == 'overlay')
              setVisible(true);
            else
              openLink(link.value);
          }
        }
        link
      />
    </>;
  }

  return (
    <>
      <Card
        title={
          <>
            <div className='flex align-content-center align-items-center'>
              <span className='pi pi-link mr-2' />
              {t('domains.programs.views.dashboard.dashboardView.components.links-card.title')}
            </div>
          </>
        }
        subTitle={t('domains.programs.views.dashboard.dashboardView.components.links-card.subTitle')}
        className='shadow-none h-full p-4'
      >
        <div
          className='flex flex-column w-full'
        >
          {
            renderLink(
              {
                icon: 'pi pi-list',
                title: t('domains.programs.views.dashboard.dashboardView.components.links-card.ruleButton'),
                value: 'overlay'
              }
            )
          }
          {
            renderLink(
              {
                icon: 'pi pi-question-circle',
                title: t('domains.programs.views.dashboard.dashboardView.components.links-card.wiki'),
                value: t('domains.programs.views.dashboard.dashboardView.components.links-card.wikiUrl')
              }
            )
          }
          {
            links?.map(
              (value) => {
                return renderLink(value);
              }
            )
          }
        </div>
      </Card>
      {
        renderDialog()
      }
    </>
  );
}

export default LinksCard;
