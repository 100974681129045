
import { t } from 'i18next';
import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import { PropsWithChildren, useState } from 'react';
import { CReferralIdentifier } from 'src/apis/api-client';

export type FiestaReferralCardProps = {
  data: CReferralIdentifier,
  textAlignment?: 'center' | 'left';
}

export function FiestaReferralCard(props: PropsWithChildren<FiestaReferralCardProps>) {
  /* Import variables from props. */  
  const { data } = props;

  const [ tooltipMessage, setTooltipMessage ] = useState<string>(t('shared.components.referral-card.labelClick'));

  /* Copies the value of the referral to the clipboard. */
  function clipboardValue() {
    if (data.value) {
      navigator
        .clipboard
          .writeText(data.value);

      setTooltipMessage(t('shared.components.referral-card.clickMessage'));
    }
  }

  /* Resets the tooltip message. */
  function resetTooltipMessage() {
    setTooltipMessage(t('shared.components.referral-card.labelClick'));
  }

  /* Renders a referral card, depending on the type. */
  function renderReferral(referral: CReferralIdentifier) {
    if (!referral)
      return <></>;

    return <>
      <Card className='flex flex-column align-items-center p-4 text-center'>
        <span className='flex text-md font-bold mb-3'>
          {t('shared.components.referral-card.labelClick')}
        </span>
        <Button
          label={t(`common.referralLabels.${referral.label}`)}
          icon='pi pi-copy'
          className='p-button-outlined'
          severity='success'
          onClick={
            () => clipboardValue()
          }
          tooltip={tooltipMessage}
          tooltipOptions={
            {
              event: 'hover',
              position: 'bottom',
              onHide: () => resetTooltipMessage()
            }
          }
        />
      </Card>
    </>;
    
    return <></>;
  }

  return (
    renderReferral(data)
  );
}

export default FiestaReferralCard;